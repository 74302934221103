import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import OSM from "ol/source/OSM";
import MVT from "ol/format/MVT";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { get as getProjection } from "ol/proj.js";
import { getWidth } from "ol/extent.js";
import SearchBAN from "ol-ext/control/SearchBAN";
import "ol-ext/control/Search.css";
import LayerSwitcherImage from "ol-ext/control/LayerSwitcherImage";
import "ol-ext/control/LayerSwitcherImage.css";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import { WMTS } from "ol/source";
import Legend from "ol-ext/legend/Legend";
import LegendCtrl from "ol-ext/control/Legend";

const resolutions = [];
const matrixIds = [];
const proj3857 = getProjection("EPSG:3857");
const maxResolution = getWidth(proj3857.getExtent()) / 256;

for (let i = 0; i < 20; i++) {
  matrixIds[i] = i.toString();
  resolutions[i] = maxResolution / Math.pow(2, i);
}

const tileGrid = new WMTSTileGrid({
  origin: [-20037508, 20037508],
  resolutions: resolutions,
  matrixIds: matrixIds,
});

const parcelleBcaeStyle = new Style({
  fill: new Fill({
    color: "rgba(162, 75, 178, 0.8)",
  }),
  stroke: new Stroke({
    color: "#802A90",
  }),
  zIndex: 0,
});

const parcelleNonBcaeStyle = new Style({
  fill: new Fill({
    color: "rgba(221, 188, 226, 0.8)",
  }),
  stroke: new Stroke({
    color: "rgb(180, 138, 186)",
  }),
});

const linSurfStyle = new Style({
  fill: new Fill({
    color: "rgb(0, 255, 255, 0.8)",
  }),
  stroke: new Stroke({
    color: "aqua",
    width: 2,
  }),
  zIndex: 1,
});

function makePattern() {
  var p = document.createElement("canvas");
  p.width = 8;
  p.height = 8;
  var pctx = p.getContext("2d");

  var x0 = 10;
  var x1 = -2;
  var y0 = 10;
  var y1 = -2;
  var offset = 8;

  pctx.strokeStyle = "rgb(0, 255, 255)";
  pctx.lineWidth = 1;
  pctx.beginPath();
  pctx.moveTo(x0, y0);
  pctx.lineTo(x1, y1);
  pctx.moveTo(x0 - offset, y0);
  pctx.lineTo(x1 - offset, y1);
  pctx.moveTo(x0 + offset, y0);
  pctx.lineTo(x1 + offset, y1);
  pctx.stroke();

  return pctx.createPattern(p, "repeat");
}
const linSurfBufferedStyle = new Style({
  fill: new Fill({
    color: makePattern(),
  }),
  zIndex: 2,
});

const map = new Map({
  target: "map",
  layers: [
    new TileLayer({
      id: "scan",
      title: "Plan IGN",
      baseLayer: true,
      source: new WMTS({
        url: "https://data.geopf.fr/wmts",
        layer: "GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2",
        format: "image/png",
        matrixSet: "PM",
        projection: "EPSG:3857",
        tileGrid: tileGrid,
        style: "normal",
        attributions: '<a href="https://www.ign.fr/" target="_blank">© IGN</a>',
      }),
    }),
    new TileLayer({
      id: "ortho",
      title: "Photos aériennes",
      baseLayer: true,
      visible: false,
      source: new WMTS({
        url: "https://data.geopf.fr/wmts",
        layer: "ORTHOIMAGERY.ORTHOPHOTOS",
        matrixSet: "PM",
        projection: "EPSG:3857",
        tileGrid: tileGrid,
        style: "normal",
        attributions: '<a href="https://www.ign.fr/" target="_blank">© IGN</a>',
      }),
    }),
    new VectorTileLayer({
      displayInLayerSwitcher: false,
      declutter: true,
      source: new VectorTileSource({
        format: new MVT(),
        url: "https://s3.eu-west-3.amazonaws.com/tiles.cartodvp.kaes.fr/{z}/{x}/{y}.pbf",
        maxZoom: 12,
      }),
      style: function (feature, resolution) {
        if (feature.get("layer") === "rpg-bcae") {
          const isBCAE = feature.get("BCAE");
          return isBCAE ? parcelleBcaeStyle : parcelleNonBcaeStyle;
        }
        if (feature.get("layer") === "bcae-lin-surf") {
          linSurfStyle.getStroke().setWidth(resolution > 1000 ? 0.5 : 2);
          return linSurfStyle;
        }
        if (feature.get("layer") === "bcae-lin-surf-buffered")
          return linSurfBufferedStyle;
      },
    }),
  ],
  view: new View({
    center: [275173.3018, 5848349.9082],
    zoom: 6,
  }),
});

// recherche d'adresse
var search = new SearchBAN({
  label: "Rechercher",
  placeholder: "Rechercher",
  position: true, // Search, with priority to geo position
});
search.on("select", function (e) {
  map.getView().animate({
    center: e.coordinate,
    zoom: Math.max(map.getView().getZoom(), 16),
  });
});
map.addControl(search);
map.addControl(new LayerSwitcherImage());

// Légende
var legend = new Legend({
  title: "Légende",
  margin: 5,
});
var legendCtrl = new LegendCtrl({
  legend: legend,
  collapsed: false,
});
map.addControl(legendCtrl);

legend.addItem({
  title: "Parcelle concernée par un DVP 20m",
  typeGeom: "Polygon",
  style: parcelleBcaeStyle,
});
legend.addItem({
  title: "Parcelle non concernée par un DVP 20m",
  typeGeom: "Polygon",
  style: parcelleNonBcaeStyle,
});
legend.addItem({
  title: "Point d'eau à protéger selon BCAE",
  typeGeom: "LineString",
  style: linSurfStyle,
});
legend.addItem({
  title: "Zone de 20m en bordure des points d'eau",
  typeGeom: "Polygon",
  style: linSurfBufferedStyle,
});
